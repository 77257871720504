import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Close from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ApplicationContext from '../ContextProvider';
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { User } from '../interfaces/UserInterfaces';
import { useQuery } from 'react-query';
import Loading from '../components/Loading';

const UserManagement = () => {
    const defaultStartDate = moment(new Date(), 'MM/DD/YYYY');
    const defaultEndDate = moment(new Date(), 'MM/DD/YYYY').add(30, 'days');
    const context = useContext(ApplicationContext);

    const [userList, setUserList] = useState<User[]>([]);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);
    const [autoPassword, setAutoPassword] = useState<boolean>(true);
    const [password, setPassword] = useState<string>('');
    const [verifyPassword, setVerifyPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<moment.Moment>(defaultStartDate);
    const [endDate, setEndDate] = useState<moment.Moment>(defaultEndDate);
    const [referralCode, setReferralCode] = useState<string>('');
    const [popover, setPopover] = useState<string>('');

    useQuery('user list', async () => {
        if (userList.length <= 0) {
            const usersResult: Response = await fetch('/users/list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const userListData = await usersResult.json();

            setUserList((userListData.data as User[]) ?? []);
        }
    });

    const updatePassword = (password: string) => {
        setPassword(password);
    };

    const updateVerifyPassword = (password: string) => {
        setVerifyPassword(password);
    };

    const validateAndSubmitUser = (form: React.FormEvent) => {
        form.preventDefault();
        createUser();
    };

    const validateEmail = (email: string) => {
        if (email.length > 0) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return setEmailError(!emailRegex.test(email));
        }

        return setEmailError(false);
    };

    const updatePopover = (popoverContent: string) => {
        // Reset our popover after 5 seconds if it isn't manually closed
        setTimeout(() => {
            setPopover('');
        }, 5000);
        return setPopover(popoverContent);
    };

    const createUser = async () => {
        const userData = JSON.stringify({
            first: firstName,
            last: lastName,
            autoPassword,
            password,
            verifyPassword,
            email,
            start_date: startDate.format('MM/DD/YYYY'),
            end_date: endDate.format('MM/DD/YYYY'),
            referral_code: referralCode,
            role: 2
        });
        context.logUserEvent({
            event_type: 'user_creation_attempted',
            user_data: {
                first: firstName,
                last: lastName,
                autoPassword,
                email,
                start_date: startDate.format('MM/DD/YYYY'),
                end_date: endDate.format('MM/DD/YYYY'),
                referral_code: referralCode,
                role: 2
            }
        });

        const userResult: Response = await fetch('/users/create', {
            method: 'POST',
            body: userData,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const userResultData: any = await userResult.json();

        if (userResult.ok) {
            context.logUserEvent({
                event_type: 'user_creation_successful',
                user_created: userResultData.data.created_user_id
            });
            // Show our popover to designate that user was created successfully
            updatePopover(`A student account for ${firstName} ${lastName} was successfully created.`);
            // Reset our controlled inputs to clear the form
            setFirstName('');
            setLastName('');
            setPassword('');
            setAutoPassword(true);
            setVerifyPassword('');
            setEmail('');
            setStartDate(defaultStartDate);
            setEndDate(defaultEndDate);
            setReferralCode('');
        } else {
            context.logUserEvent({ event_type: 'user_creation_failed' });
            updatePopover(`${userResultData.message}`);
        }
    };

    console.log(userList);

    return (
        <Grid container justifyContent={'center'} width={'100%'}>
            {userList.length <= 0 ? (
                <Loading />
            ) : (
                <Grid item width={'75%'}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell>Email</TableCell>
                                <TableCell>First</TableCell>
                                <TableCell>Last</TableCell>
                                <TableCell>Referral Code</TableCell>
                                <TableCell>Role</TableCell>
                            </TableHead>
                            <TableBody>
                                {userList.map((user) => {
                                    return (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.first}</TableCell>
                                            <TableCell>{user.last}</TableCell>
                                            <TableCell>{user.referral_code}</TableCell>
                                            <TableCell>{user.role}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {/* <form onSubmit={validateAndSubmitUser}>
                <Grid container alignItems="center" justifyContent="center" direction="column">
                    <Grid item>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <TextField
                                id="first_name"
                                type={'text'}
                                required
                                value={firstName}
                                onChange={(firstName) => {
                                    setFirstName(firstName.target.value);
                                }}
                                variant={'outlined'}
                                label="First Name"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <TextField
                                id="last_name"
                                type={'text'}
                                required
                                value={lastName}
                                onChange={(lastName) => {
                                    setLastName(lastName.target.value);
                                }}
                                variant={'outlined'}
                                label="Last Name"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <TextField
                                id="email"
                                type={'text'}
                                required
                                helperText={emailError ? 'Invalid email supplied' : 'Example: John@gmail.com'}
                                error={emailError}
                                value={email}
                                onChange={(emailElem) => {
                                    setEmail(emailElem.target.value);
                                }}
                                onBlur={() => {
                                    validateEmail(email);
                                }}
                                variant={'outlined'}
                                label="Email"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            label="Automatically Generate Password"
                            control={
                                <Checkbox
                                    id="auto-password"
                                    name="auto-password"
                                    checked={autoPassword}
                                    onChange={() => {
                                        setAutoPassword(!autoPassword);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    {!autoPassword && (
                        <>
                            <Grid item>
                                <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                                    <TextField
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(changedVerifyPassword) => {
                                            updatePassword(changedVerifyPassword.target.value);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => {
                                                            setShowPassword(!showPassword);
                                                        }}
                                                        onMouseDown={() => {
                                                            setShowPassword(!showPassword);
                                                        }}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        label="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                                    <TextField
                                        id="verify_password"
                                        type={showVerifyPassword ? 'text' : 'password'}
                                        value={verifyPassword}
                                        onChange={(changedPassword) => {
                                            updateVerifyPassword(changedPassword.target.value);
                                        }}
                                        onBlur={() => {
                                            return;
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle verify password visibility"
                                                        onClick={() => {
                                                            setShowVerifyPassword(!showVerifyPassword);
                                                        }}
                                                        onMouseDown={() => {
                                                            setShowVerifyPassword(!showVerifyPassword);
                                                        }}
                                                        edge="end"
                                                    >
                                                        {showVerifyPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        label="Verify Password"
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <DatePicker
                                label="Access Start Date"
                                value={startDate}
                                disablePast
                                inputFormat="MM/DD/yyyy"
                                onChange={(startDate) => {
                                    if (startDate) {
                                        setStartDate(startDate);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <DatePicker
                                label="Access End Date"
                                value={endDate}
                                disablePast
                                inputFormat="MM/DD/yyyy"
                                onChange={(endDate) => {
                                    if (endDate) {
                                        setEndDate(endDate);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: { m: '50ch', sm: '30ch' } }} variant="outlined">
                            <TextField
                                id="referral_code"
                                type={'text'}
                                helperText={'Example: aman1'}
                                value={referralCode}
                                onChange={(referralCode) => {
                                    setReferralCode(referralCode.target.value);
                                }}
                                variant={'outlined'}
                                label="Referral Code"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button id="submit_button" variant={'outlined'} type={'submit'}>
                            Create User
                        </Button>
                    </Grid>
                    <Grid item width={'100%'} paddingTop={'25px'} paddingBottom={'25px'}>
                        <Divider variant="middle" />
                    </Grid>
                    <Grid item>
                        <Button
                            variant={'outlined'}
                            onClick={async () => {
                                await fetch('/users/update_referrals', {
                                    method: 'POST',
                                    body: JSON.stringify({ something: 'random' }),
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                            }}
                        >
                            Register Referral Codes for all Users
                        </Button>
                    </Grid>
                </Grid>
            </form> */}
            <Popover
                id="informational_modal"
                disableAutoFocus
                open={popover.length > 1}
                anchorEl={document.getElementById('submit_button')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                style={{ marginTop: '40px' }}
            >
                <Paper style={{ padding: '10px' }}>
                    {popover}
                    <IconButton
                        aria-label="close popover"
                        onClick={() => {
                            setPopover('');
                        }}
                        onMouseDown={() => {
                            setPopover('');
                        }}
                        edge="end"
                        style={{
                            display: popover.length > 1 ? 'inline-flex' : 'none',
                            marginLeft: '5px',
                            marginRight: '5px'
                        }}
                    >
                        <Close />
                    </IconButton>
                </Paper>
            </Popover>
        </Grid>
    );
};

export default UserManagement;
