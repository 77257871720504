import { Typography, Button } from '@mui/material';
import './css/Home.css';

const Home = () => {
    return (
        <div className="App" style={{ background: 'transparent', height: '100%' }}>
            <header className="App-header" style={{ background: 'transparent' }}>
                <iframe
                    width="60%"
                    src="https://www.youtube-nocookie.com/embed/ZOV5IS8rdk4?si=gVrS5WI-b15lg4hS"
                    title="YouTube video player - CPE Online Academy Teaser"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Typography alignContent={'center'} padding={'2rem'} variant="body1" maxWidth={'75vw'}>
                    Watch Dr. Thompson from the famous CPE Bootcamp perform her version of the CPE surgery, anesthesia,
                    radiology, necropsy, equine, farm animal and small animal medicine sections. $700 for a 3-month
                    subscription.
                    <br />
                    To enroll, email us at
                    <Button
                        onClick={() => {
                            navigator.clipboard.writeText('management@cpeonlineacademy.com');
                        }}
                        sx={{ color: '#377dca' }}
                    >
                        management@cpeonlineacademy.com (click to copy)
                    </Button>
                    and send us a screenshot of your CPE enrollment website(must include your name for verification
                    purposes), we will send you a payment link followed by login information for your personal account.
                    <br />
                </Typography>
                <Typography variant="h6">
                    *Disclaimer: By enrolling in this course, I agree to all terms and conditions of use, most
                    importantly, I respect the proprietary nature of this content and will not attempt to save, download
                    or distribute.
                </Typography>
            </header>
        </div>
    );
};

export default Home;
