import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PausePresentation from '@mui/icons-material/PausePresentation';
import Fullscreen from '@mui/icons-material/Fullscreen';
import { useState, useEffect } from 'react';
import './css/ClassroomContent.css';
import { Content, ClassroomProps, UserClassRoomTracking } from '../../interfaces/ClassroomInterfaces';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Grid, List, ListItem, Popover } from '@mui/material';
import ClassroomFileBrowser from './ClassroomFileBrowser';

const ClassroomContent = (props: ClassroomProps) => {
    const updateUserProgress = props.updateUserProgress;
    const classroomContent: Content = props.classroomContent;
    const userClassroomTracking: Array<UserClassRoomTracking> = props.userClassroomTracking;

    const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
    const [videoLoading, setVideoLoading] = useState<boolean>(true);
    const [seekTime, setSeekTime] = useState<number>(-1);
    const [minSeek, setMinSeekTime] = useState<number>(0);
    const [maxSeek, setMaxSeekTime] = useState<number>(1);
    const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false);
    const [speedElementAnchor, setSpeedElementAnchor] = useState<HTMLButtonElement | null>(null);
    const [speedPopoverOpen, setSpeedPopoverOpen] = useState<boolean>(false);
    const [currentPlaybackSpeed, setCurrentPlaybackSpeed] = useState<number>(1);
    const videoContent: HTMLElement = document.getElementById('video_content') as HTMLElement;
    let videoElement: HTMLVideoElement = document.getElementById('video_element') as HTMLVideoElement;

    useEffect(() => {
        if (userClassroomTracking[0]?.video_timestamp > 0 && seekTime === -1) {
            setSeekTime(Number(userClassroomTracking[0].video_timestamp) ?? 0);
        }
    }, [userClassroomTracking, seekTime]);

    useEffect(() => {
        if (videoElement) {
            videoElement.playbackRate = currentPlaybackSpeed;
        }
    }, [currentPlaybackSpeed]);

    useEffect(() => {
        setVideoLoading(true);
        setSeekTime(0);
        videoElement = document.getElementById('video_element') as HTMLVideoElement;
        if (userClassroomTracking.length) {
            setSeekTime(Number(userClassroomTracking[0].video_timestamp) ?? 0);
        }
        videoElement.load();
        setVideoPlaying(false);
    }, [classroomContent]);

    const formatVideoTime = (duration: number | string) => {
        let m: number | string = Math.floor((duration as number) / 60);
        m = m >= 10 ? m : '0' + m;
        duration = Math.floor((duration as number) % 60);
        duration = duration >= 10 ? duration : '0' + duration;
        return m + ':' + duration;
    };

    const startVideo = async () => {
        updateUserProgress(seekTime, userClassroomTracking[0]?.completed ?? false);
        if (videoElement.currentTime !== seekTime) {
            videoElement.currentTime = seekTime;
        }
        setVideoPlaying(true);
        videoElement.play();
    };

    const pauseVideo = async () => {
        updateUserProgress(seekTime, userClassroomTracking[0]?.completed ?? false);
        setVideoPlaying(false);
        videoElement.pause();
    };

    const toggleFullscreen = () => {
        if (!fullscreenEnabled && videoContent.requestFullscreen) {
            videoContent.requestFullscreen();
            setFullscreenEnabled(true);
        } else if (fullscreenEnabled) {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                setFullscreenEnabled(false);
            } else {
                videoContent.requestFullscreen();
            }
        }
    };

    return classroomContent.content_title ? (
        <Card sx={{ display: 'flex', flexDirection: 'column', background: 'transparent' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {classroomContent.content_title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {classroomContent.content_subtitle}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', px: 1, pb: 1 }}></Box>
            </Box>
            <CardMedia
                id="video_content"
                component="div"
                className="video-content player"
                sx={{ position: 'relative', display: 'flex' }}
                onLoadedData={() => {
                    videoElement = document.getElementById('video_element') as HTMLVideoElement;
                    setMinSeekTime(videoElement.seekable.start(0));
                    setMaxSeekTime(videoElement.seekable.end(0));
                    setVideoLoading(false);
                }}
            >
                <video
                    id="video_element"
                    className={fullscreenEnabled ? 'video-element-fullscreen' : 'video-element'}
                    onContextMenu={(contextMenu: React.MouseEvent<HTMLVideoElement>) => {
                        contextMenu.preventDefault();
                    }}
                    onEnded={() => {
                        setVideoPlaying(false);
                        updateUserProgress(seekTime, true);
                    }}
                    onTimeUpdate={(timeUpdateEvent: React.SyntheticEvent<HTMLVideoElement, Event>) => {
                        const timeUpdatelement = timeUpdateEvent.target as HTMLVideoElement;
                        setSeekTime(timeUpdatelement.currentTime);
                    }}
                >
                    <source src={classroomContent.context_video ?? ''} />
                </video>
                <Box
                    className={videoPlaying ? 'controls' : ''}
                    style={{ position: 'absolute', bottom: '0', width: '100%', background: 'rgba(0, 0, 0, 0.3)' }}
                >
                    {!videoLoading && (
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{ mt: 1, width: '90%', left: '5%', position: 'relative' }}
                            alignItems="center"
                        >
                            <Slider
                                aria-label="time seek slider"
                                className={'video-slider'}
                                min={minSeek}
                                max={maxSeek}
                                value={seekTime}
                                onChangeCommitted={(
                                    seekEvent: Event | React.SyntheticEvent<Element, Event>,
                                    seekValue: number | Array<number>
                                ) => {
                                    setSeekTime(Number(seekValue));
                                    videoElement.currentTime = Number(seekValue);
                                    updateUserProgress(Number(seekValue), userClassroomTracking[0]?.completed ?? false);
                                }}
                            />
                        </Stack>
                    )}
                    <IconButton
                        disabled={videoLoading}
                        aria-label={videoPlaying ? 'pause' : 'play'}
                        onClick={() => {
                            videoPlaying ? pauseVideo() : startVideo();
                        }}
                        sx={{ left: '45%', color: '#eae6e6' }}
                    >
                        {videoPlaying ? (
                            <PausePresentation sx={{ height: 38, width: 38 }} />
                        ) : (
                            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                        )}
                    </IconButton>
                    <IconButton
                        disabled={videoLoading}
                        aria-label={'fullscreen toggle'}
                        onClick={() => {
                            toggleFullscreen();
                        }}
                        sx={{ left: '45%', color: '#eae6e6' }}
                    >
                        {<Fullscreen sx={{ height: 38, width: 38 }} />}
                    </IconButton>
                    <IconButton
                        disabled={videoLoading}
                        aria-label={'fullscreen toggle'}
                        onClick={(event) => {
                            setSpeedElementAnchor(event.currentTarget);
                            setSpeedPopoverOpen(true);
                        }}
                        sx={{ left: '45%', color: '#eae6e6' }}
                    >
                        {
                            <Typography variant="h5" sx={{ height: 38, width: 38 }}>
                                {currentPlaybackSpeed}x
                            </Typography>
                        }
                    </IconButton>
                    <Popover
                        anchorEl={speedElementAnchor}
                        open={speedPopoverOpen}
                        onClose={() => {
                            setSpeedPopoverOpen(false);
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                    >
                        <List>
                            <ListItem>
                                <IconButton
                                    sx={{ width: 38, height: 38 }}
                                    onClick={() => {
                                        setCurrentPlaybackSpeed(1);
                                        setSpeedPopoverOpen(false);
                                    }}
                                >
                                    1x
                                </IconButton>
                            </ListItem>
                            <ListItem>
                                <IconButton
                                    sx={{ width: 38, height: 38 }}
                                    onClick={() => {
                                        setCurrentPlaybackSpeed(1.25);
                                        setSpeedPopoverOpen(false);
                                    }}
                                >
                                    1.25x
                                </IconButton>
                            </ListItem>
                            <ListItem>
                                <IconButton
                                    sx={{ width: 38, height: 38 }}
                                    onClick={() => {
                                        setCurrentPlaybackSpeed(1.5);
                                        setSpeedPopoverOpen(false);
                                    }}
                                >
                                    1.5x
                                </IconButton>
                            </ListItem>
                            <ListItem>
                                <IconButton
                                    sx={{ width: 38, height: 38 }}
                                    onClick={() => {
                                        setCurrentPlaybackSpeed(2);
                                        setSpeedPopoverOpen(false);
                                    }}
                                >
                                    2x
                                </IconButton>
                            </ListItem>
                            <ListItem>
                                <IconButton
                                    sx={{ width: 38, height: 38 }}
                                    onClick={() => {
                                        setCurrentPlaybackSpeed(2.5);
                                        setSpeedPopoverOpen(false);
                                    }}
                                >
                                    2.5x
                                </IconButton>
                            </ListItem>
                            <ListItem>
                                <IconButton
                                    sx={{ width: 38, height: 38 }}
                                    onClick={() => {
                                        setCurrentPlaybackSpeed(3);
                                        setSpeedPopoverOpen(false);
                                    }}
                                >
                                    3x
                                </IconButton>
                            </ListItem>
                        </List>
                    </Popover>
                    {videoElement && !videoLoading && (
                        <>
                            <IconButton
                                disabled={true}
                                aria-label={'time display'}
                                sx={{ left: 0, color: '#eae6e6 !important' }}
                            >
                                {formatVideoTime(videoElement.currentTime)}
                            </IconButton>
                            <IconButton
                                disabled={true}
                                aria-label={'time display'}
                                sx={{ left: '60%', color: '#eae6e6 !important' }}
                            >
                                {formatVideoTime(videoElement.duration)}
                            </IconButton>
                        </>
                    )}
                </Box>
            </CardMedia>
            <Grid container width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item className="navigation-button">
                    {props.previousLink ? (
                        <Link to={props.previousLink}>
                            <IconButton color="primary" sx={{ borderRadius: 0 }}>
                                <ArrowLeft sx={{ width: '2rem', height: '2rem' }} />
                                Previous Video
                            </IconButton>
                        </Link>
                    ) : (
                        <IconButton disabled disableRipple color="primary" sx={{ borderRadius: 0 }}>
                            <ArrowLeft sx={{ width: '2rem', height: '2rem' }} />
                            Previous Video
                        </IconButton>
                    )}
                </Grid>
                <Grid item className="navigation-button">
                    {props.nextLink ? (
                        <Link to={props.nextLink}>
                            <IconButton color="primary" sx={{ borderRadius: 0 }}>
                                Next Video
                                <ArrowRight sx={{ width: '2rem', height: '2rem' }} />
                            </IconButton>
                        </Link>
                    ) : (
                        <IconButton disabled disableRipple color="primary" sx={{ borderRadius: 0 }}>
                            Next Video
                            <ArrowRight sx={{ width: '2rem', height: '2rem' }} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                width={'100%'}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                padding={'10px'}
            >
                <Grid item>
                    <Typography variant="h5">
                        Disclaimer: Respect the proprietary nature of this content and do not attempt to save, download
                        or distribute.
                    </Typography>
                </Grid>
            </Grid>
            {Object.values(classroomContent.files).length ? (
                <ClassroomFileBrowser classroomFiles={classroomContent.files} />
            ) : (
                <></>
            )}
        </Card>
    ) : (
        <Loading />
    );
};

export default ClassroomContent;
