import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Home from '@mui/icons-material/Home';
import Login from '@mui/icons-material/Login';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import School from '@mui/icons-material/School';
import FileDownload from '@mui/icons-material/FileDownload';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import './css/Sidebar.css';
import ApplicationContext from '../ContextProvider';
import { useContext, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import AccountCircleTwoTone from '@mui/icons-material/AccountCircleTwoTone';
import { logout } from '../utilities/Auth';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { KeyboardDoubleArrowLeft, LinkRounded } from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    height: '57px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
        height: '100%',
        overflow: 'initial'
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

const Sidebar = () => {
    const context = useContext(ApplicationContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuElem = document.getElementById('menu');

    const handleDrawerChange = () => {
        context.setSidebarOpen(!context.sidebarOpen);
    };

    const toggleAccountMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleAccountMenuClose = () => {
        setMenuOpen(false);
    };

    return (
        <Box sx={{ display: 'inline-flex' }}>
            <AppBar position="fixed" open={context.sidebarOpen}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                            handleDrawerChange();
                        }}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(context.sidebarOpen && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Avatar
                        sx={
                            context.verifiedLogin
                                ? { display: { xs: 'none', md: 'flex' }, borderRadius: '0px', width: '100%' }
                                : { borderRadius: '0px', width: '100%' }
                        }
                        src="/images/logo_cpe_online_academy_thompson_4c_s.jpg"
                        className="headerLogo"
                        alt="logo"
                    />
                    <Typography variant="h6" noWrap component="div">
                        CPE Online Academy
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {context.verifiedLogin && (
                        <>
                            <Button
                                id="menu"
                                className={context.darkThemeEnabled ? 'menuButtonDark' : 'menuButtonLight'}
                                onClick={() => {
                                    toggleAccountMenu();
                                }}
                            >
                                <AccountCircleTwoTone sx={context.darkThemeEnabled ? { color: '#fff' } : {}} />
                            </Button>
                            <Menu
                                anchorEl={menuElem}
                                id="account-menu"
                                open={menuOpen}
                                onClose={handleAccountMenuClose}
                                onClick={toggleAccountMenu}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <ToggleButton
                                    sx={{
                                        width: '100%',
                                        marginBottom: '20px'
                                    }}
                                    value={context.darkThemeEnabled}
                                    onClick={() => {
                                        context.setDarkThemeEnabled(!context.darkThemeEnabled);
                                    }}
                                    aria-label="toggle dark theme"
                                >
                                    {context.darkThemeEnabled ? <DarkMode /> : <LightMode />}
                                </ToggleButton>
                                <MenuItem
                                    onClick={() => {
                                        logout(context);
                                        handleDrawerChange();
                                    }}
                                >
                                    <ListItemIcon>
                                        <LogoutOutlined />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={context.sidebarOpen}>
                <DrawerHeader>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                            handleDrawerChange();
                        }}
                        edge="start"
                    >
                        {context.sidebarOpen ? <KeyboardDoubleArrowLeft /> : <MenuIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {/* List our menu links */}
                    {!context.verifiedLogin && (
                        <>
                            <Link to={'/'} className="headerLink">
                                <ListItem key={'home'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Home />
                                        </ListItemIcon>
                                        <ListItemText primary={'Home'} sx={{ opacity: context.sidebarOpen ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                            <Link to={'/login'} className="headerLink">
                                <ListItem key={'login'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Login />
                                        </ListItemIcon>
                                        <ListItemText primary={'Login'} sx={{ opacity: context.sidebarOpen ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <ToggleButton
                                sx={{
                                    width: '100%',
                                    marginBottom: '20px'
                                }}
                                value={context.darkThemeEnabled}
                                onClick={() => {
                                    context.setDarkThemeEnabled(!context.darkThemeEnabled);
                                }}
                                aria-label="toggle dark theme"
                            >
                                {context.darkThemeEnabled ? <DarkMode /> : <LightMode />}
                            </ToggleButton>
                        </>
                    )}
                    {context.verifiedLogin && context.permissionLevel === 'Admin' && (
                        <>
                            <Link to={'/user_creation'} className="headerLink">
                                <ListItem key={'user_creation'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <PersonAddAlt1Icon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={'Create New User'}
                                            sx={{ opacity: context.sidebarOpen ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <Link to={'/user_management'} className="headerLink">
                                <ListItem key={'user_management'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <PersonAddAlt1Icon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={'Manage Users'}
                                            sx={{ opacity: context.sidebarOpen ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        </>
                    )}
                    {context.verifiedLogin && (
                        <>
                            <Link to={'/classroom'} className="headerLink">
                                <ListItem key={'classroom'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <School />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={'Classroom'}
                                            sx={{ opacity: context.sidebarOpen ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <Link
                                to={'/files'}
                                className="headerLink"
                                onClick={() => {
                                    context.setSidebarOpen(false);
                                }}
                            >
                                <ListItem key={'files'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <FileDownload />
                                        </ListItemIcon>
                                        <ListItemText primary={'Files'} sx={{ opacity: context.sidebarOpen ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <Link
                                to={'/links'}
                                className="headerLink"
                                onClick={() => {
                                    context.setSidebarOpen(false);
                                }}
                            >
                                <ListItem key={'links'} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: context.sidebarOpen ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: context.sidebarOpen ? 3 : 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <LinkRounded />
                                        </ListItemIcon>
                                        <ListItemText primary={'Links'} sx={{ opacity: context.sidebarOpen ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <Box
                                width={'100%'}
                                marginTop={'5rem'}
                                display={context.sidebarOpen ? 'auto' : 'none'}
                                sx={{ whiteSpace: 'break-spaces' }}
                            >
                                <Button
                                    sx={{ width: '100%', whiteSpace: 'normal' }}
                                    variant="contained"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            `Hi your friend would like to refer you to the CPE Online Academy, their unique referral code is: ${context.referralCode}`
                                        );
                                    }}
                                >
                                    Want to refer a friend? Click here to copy your unique referral code and invitation
                                    message!
                                </Button>
                                <Box width={'100%'} textAlign={'center'}>
                                    <Typography variant="caption">
                                        Details of referral system <Link to={'/referral_details'}>here</Link>.
                                        <br />
                                        Referral bonus is 1 week of free access for the person referred and the person
                                        doing the referring.
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </List>
            </Drawer>
        </Box>
    );
};

export default Sidebar;
