import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ContextProvider from './ContextProvider';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from 'react-query';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const EntryPoint = (): JSX.Element => {
    const [loginExpires, setLoginExpires] = React.useState<Date | undefined>(undefined);
    const [darkThemeEnabled, setDarkThemeEnabled] = React.useState<boolean>(true);
    const [permissionLevel, setPermissionLevel] = React.useState<string>('');
    const [verifiedLogin, setVerifiedLogin] = React.useState<boolean>(false);
    const [referralCode, setReferralCode] = React.useState<string>('');
    const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);

    const logUserEvent = async (event: []): Promise<void> => {
        await fetch('/logging/user', {
            method: 'POST',
            body: JSON.stringify({ event }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    useEffect(() => {
        localStorage.getItem('cpeDarkMode') === 'true' ? setDarkThemeEnabled(true) : setDarkThemeEnabled(false);
    }, []);

    const contextState = {
        loginExpires,
        setLoginExpires,
        darkThemeEnabled,
        setDarkThemeEnabled: (setting: boolean) => {
            localStorage.setItem('cpeDarkMode', `${setting}`);
            setDarkThemeEnabled(setting);
        },
        permissionLevel,
        setPermissionLevel,
        verifiedLogin,
        setVerifiedLogin,
        referralCode,
        setReferralCode,
        logUserEvent,
        sidebarOpen,
        setSidebarOpen
    };

    const queryClient = new QueryClient();

    const darkTheme = createTheme({
        palette: {
            mode: darkThemeEnabled ? 'dark' : 'light',
            primary: {
                main: '#003064'
            },
            background: {
                default: darkThemeEnabled ? '#5b5b5b' : ''
            }
        },
        typography: {
            allVariants: {
                letterSpacing: 0,
                fontWeight: '700'
            }
        },
        components: {
            MuiDatePicker: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'red'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none'
                    }
                }
            }
        }
    });

    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ContextProvider.Provider value={contextState}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <ThemeProvider theme={darkTheme}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </ThemeProvider>
                    </LocalizationProvider>
                </ContextProvider.Provider>
            </QueryClientProvider>
        </React.StrictMode>
    );
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<EntryPoint />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
