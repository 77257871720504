import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const Links = () => {
    const generalLinks = [
        {
            title: 'Finding Hindlimb Lameness',
            link: 'https://www.youtube.com/watch?v=O-_UFkDiq0w',
            img: 'https://i.ytimg.com/vi/O-_UFkDiq0w/mqdefault.jpg'
        },
        {
            title: 'Finding Forelimb Lameness',
            link: 'https://www.youtube.com/watch?v=F8103_4Rq2k',
            img: 'https://i.ytimg.com/vi/F8103_4Rq2k/mqdefault.jpg'
        },
        {
            title: 'Picking Up Horse Feet',
            link: 'https://www.youtube.com/watch?v=bvuHXad-2wc',
            img: 'https://i.ytimg.com/vi/bvuHXad-2wc/mqdefault.jpg'
        },
        {
            title: 'Approaching and Catching Horses',
            link: 'https://www.youtube.com/watch?v=0CABQdO1XAg',
            img: 'https://i.ytimg.com/vi/0CABQdO1XAg/mqdefault.jpg'
        },
        {
            title: 'Wrapping Horse Legs',
            link: 'https://www.youtube.com/watch?v=GicfRy-70V4',
            img: 'https://i.ytimg.com/vi/GicfRy-70V4/mqdefault.jpg'
        },
        {
            title: 'Equine Lameness Exam',
            link: 'https://www.youtube.com/watch?v=A1gisjYuuzY',
            img: 'https://i.ytimg.com/vi/A1gisjYuuzY/mqdefault.jpg'
        },
        {
            title: 'Udder Preparation',
            link: 'https://www.youtube.com/watch?v=8xXvruUFEl0',
            img: 'https://i.ytimg.com/vi/8xXvruUFEl0/mqdefault.jpg'
        },
        {
            title: 'Bovine Mastitis Test',
            link: 'https://www.youtube.com/watch?v=vXSW3xVy8Sc&t=217s',
            img: 'https://i.ytimg.com/vi/vXSW3xVy8Sc/mqdefault.jpg'
        },
        {
            title: 'Collecting Bovine Milk',
            link: 'https://www.youtube.com/watch?v=on0SrRkDFtk',
            img: 'https://i.ytimg.com/vi/on0SrRkDFtk/mqdefault.jpg'
        },
        {
            title: 'Clinical Bovine Exam',
            link: 'https://www.youtube.com/watch?v=FaZcJHuDvlk',
            img: 'https://i.ytimg.com/vi/FaZcJHuDvlk/mqdefault.jpg'
        },
        {
            title: 'Small Animal Radiology',
            link: 'https://www.youtube.com/watch?v=glFIEbKKAho',
            img: 'https://i.ytimg.com/vi/glFIEbKKAho/mqdefault.jpg'
        },
        {
            title: 'Small Animal Necropsy',
            link: 'https://www.youtube.com/watch?v=PUuves6t2p8',
            img: 'https://i.ytimg.com/vi/PUuves6t2p8/mqdefault.jpg'
        },
        {
            title: 'Monitoring Vital Signs',
            link: 'https://www.youtube.com/watch?v=anfYTeEj1-Y',
            img: 'https://i1.ytimg.com/vi/anfYTeEj1-Y/hqdefault.jpg'
        },
        {
            title: 'SAM Anesthesia',
            link: 'https://www.youtube.com/watch?v=NMlsSvps75s',
            img: 'https://i1.ytimg.com/vi/NMlsSvps75s/hqdefault.jpg'
        },
        {
            title: 'Virtual Oquendo Campus',
            link: 'https://my.matterport.com/show/?m=2JyY1gXdYWc',
            img: 'https://my.matterport.com/api/v1/player/models/2JyY1gXdYWc/thumb?width=1684&dpr=1&disable=upscale'
        },
        {
            title: 'Aging Horses',
            link: 'https://web.archive.org/web/20240803011137/http://www.vivo.colostate.edu/hbooks/pathphys/digestion/pregastric/aginghorses.html',
            img: 'https://web.archive.org/web/20240803011137im_/http://www.vivo.colostate.edu/hbooks/pathphys/digestion/pregastric/galgroove.jpg'
        },
        {
            title: 'Lamness Trainer',
            link: 'https://www.lamenesstrainer.com',
            img: 'https://www.lamenesstrainer.com/wp-content/uploads/2020/05/Mod01MaskedAI_small.png'
        },
        {
            title: 'Bovine Pregnancy',
            link: 'http://therio.vetmed.lsu.edu/bovine_pregnancy.htm',
            img: 'https://2.bp.blogspot.com/-6QzaEHDHpKQ/VhPBFAq95LI/AAAAAAAAKj4/zWCtg9Qiibw/s1600/UT1.jpg'
        },
        {
            title: 'Bovine Diseases',
            link: 'https://www.thecattlesite.com/diseaseinfo/',
            img: 'https://cdn.globalagmedia.com/Pagebuilder/CattleIcon.svg'
        },
        {
            title: 'Ruminant Diseases',
            link: 'http://www.sheep101.info/201/diseasesa-z.html',
            img: 'http://www.sheep101.info/201/Images/sheep201newlogo..jpg'
        },
        {
            title: 'SAM Joint Radiography',
            link: 'https://todaysveterinarypractice.com/wp-content/uploads/sites/4/2016/04/T1203C03.pdf',
            img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAb1BMVEUBdHsAbnUAaXEAcXgAa3KOvL7i7u/Z6uqjxcZgnKHW6enF3N52qq0Ac3qxztD////3/P0VeYCmxsoAcnk0iI5XmZ5Lk5hyp60rhIo+hYvS4uQAZW3p8vSHtLeUuLwAaHAjf4k4jpNWoKRmqq1ImKDY5wnAAAAA/ElEQVR4AdSOB5bDIAxEkcEtNh4nYBb3ev8zrkhPbpChSKOPnhA/JooeomDl3ajgKE7SuzLFLE/SE+enoiQtVIWH6jPbAmlysxcSxjauQuJcCfyR8TXKBFnrOn4rhJFRhrSX/YCOaERNCaZ5zgEvWMQwElyHpxQXlaByecKdL6jPQGwBL+8zY3rB8JckQ6dMgqQoqobZC8oGrJw0z+wjJcU71NyCQZgA6UZeUNAEZEp/wanuAjR+GLzRuqsfUBujVT9rjkb2s+Q498SWl7DWeu/tstjWeusXb28VxzWxbvu2H7Zp9mML6b6tGyeO3SaMDLrd8pWbcP9vaKU9AGDsFQsL3R22AAAAAElFTkSuQmCC'
        }
    ];

    return (
        <Paper elevation={2} sx={{ p: 2, background: 'transparent' }}>
            <Typography variant="h4" sx={{ mx: 1, pt: 1 }}>
                Helpful Links from Dr. Thompson
            </Typography>
            <Typography variant="caption" sx={{ mx: 1, my: 0 }}>
                All content within these links owned by the original author
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                {generalLinks.map((link) => {
                    return (
                        <Link
                            key={link.link}
                            sx={{
                                m: 1,
                                backgroundImage: `url(${link.img})`,
                                backgroundSize: 'cover',
                                borderRadius: 1.5,
                                textDecoration: 'none'
                            }}
                            href={link.link}
                            target="_blank"
                        >
                            <Paper
                                elevation={4}
                                sx={{
                                    width: '128px',
                                    height: '50px',
                                    marginTop: '85px',
                                    overflow: 'hidden',
                                    textAlign: 'center'
                                }}
                            >
                                {link.title}
                            </Paper>
                        </Link>
                    );
                })}
            </Box>
        </Paper>
    );
};

export default Links;
